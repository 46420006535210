
@use './_tools' as *
@use './_vars' as *

header
    box-shadow: 0 4px 8px rgba(0,0 ,0 ,.1 )
    .container
        @extend %Flex-between
        height: 120px
        position: relative
        @media (max-width:1100px)
            padding-right: 44px
    nav
        width: 100%
        display: flex
        justify-content: flex-end
        padding-right: 24px
    img
      
        height: 30px
    .fa-bars
        cursor: pointer
        font-size: 24px
        border-radius: 50%
        height: 36px
        color: $yellow
        width: 36px
        text-align: center
        line-height: 36px
        transition: $transition
        right: 0
        @extend %Abs-center-Y
        &:hover
            background-color: #ccc9

    .logo
        color: $main_color
        @extend %Flex-between
        column-gap: 26px
        h1
            position: relative
            margin: 0
            font-weight: 500
            font-size: 26px
            &::after
                content: ""
                @extend %Abs-center-Y
                left: -12px
                height: 100%
                width: 1px
                background-color: $yellow
                font-weight: 500
        @media (max-width:600px)
            h1
                display: none

    @media (min-width:1100px)
        .mainMenu .fa-xmark
            display: none
        nav .fa-bars
            display: none
    @media (max-width:1100px)
        >.profile
            padding-inline: 20px 48px
        nav .mainMenu
            display: none
            z-index: 9
            animation: goDown 1s forwards
            width: 100vw
            height: 100vh
            position: fixed
            left: 0
            bottom: 100%
            opacity: 0
            background-color: white
            justify-content: center
            align-items: center
            flex-flow: column
            padding-block: 32px
            row-gap: 32px
            font-size: 26px
            &.act
                display: flex
            .fa-xmark
                position: absolute
                left: 50%
                transform: translateX(-50%)
                top: 160px
                cursor: pointer
                border-radius: 50%
                height: 36px
                width: 36px
                text-align: center
                line-height: 36px
                transition: $transition

                &:hover
                    background-color: #eee

    .mainMenu
        @extend %Flex-between
        column-gap: 16px
        font-size: 16px
        li
            a
                transition: border $transition
                font-size: 14px
                color: $main_color
                white-space: nowrap
                display: block
                padding-bottom: 3px
                border-bottom: transparent 2px solid

                &.act
                    font-weight: 600
                    border-bottom-color: $yellow
                &:hover
                    border-bottom-color: $yellow

    .profile
        border-left: 1px solid $yellow
        cursor: pointer
        white-space: nowrap
        font-size: 14px
        font-weight: 300
        padding-left: 12px
        margin-left: 50px
        position: relative
        .fa-angle-down
            margin-left: 6px
            color: $yellow
@keyframes goDown
    to
        opacity: 1
        bottom: 0


header .profile .dropdown 
    border-radius: 5px
    position: absolute
    top: 130%
    background-color: white
    box-shadow: 0 10px 27px rgb(203 200 200)
    transition: 0.3s
    overflow: hidden
    max-height: 0 
    left: 0px
    li
        padding:  16px
        &:hover
            background-color: #eee9
header .profile ul.act 
    max-height: 100px 
